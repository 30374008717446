<template>
  <b-card>
    <h2 class="text-primary">Course Information</h2>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.featuredImage"
            height="240"
            alt=""
            width="480"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          :state="Boolean(file)"
          v-model="file"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="fileSelected"
        />
        <!--/ upload button -->

        <!-- reset -->

        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 2 MB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="12">
          <b-form-group label="Course Title" label-for="course-title">
            <b-form-input
              v-model="optionsLocal.title"
              placeholder="Course Title"
              name="title"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Course Code" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.courseCode"
              name="name"
              placeholder="Mobile"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Instructor" label-for="account-e-mail">
            <author-selector
              :initialAuthor="optionsLocal.ClientInstructors"
              @authorChanged="authorChanged"
            />
          </b-form-group>
        </b-col>

      </b-row>
        <b-row>
           <b-col cols="6" class="mt-75">
          <b-form-group label="Course Type" label-for="course-category">
           <b-badge variant="primary">
        {{ optionsLocal.coursemode }}
      </b-badge>
          
          </b-form-group>



        </b-col>
          </b-row>
        <b-row>
             <b-col cols="3"  >
          

            <b-form-group label="Course Length (in minutes)" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.courseaward"
              name="name"
              placeholder="Course Length"
            />
          </b-form-group>


        </b-col>
            <b-col cols="3"  >
          

            <b-form-group label="Credit Hours / Award" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.courseDurationMinutes"
              name="name"
              placeholder="Credit Hours"
            />
          </b-form-group>


        </b-col>

          <b-col cols="6" >
   

            <b-form-group label="Course Level" label-for="course-category">
            <b-form-input
              v-model="optionsLocal.courseLevel"
              name="name"
              placeholder="BEGINNER"
            />
            </b-form-group>


        </b-col>
           </b-row>
        <b-row>

         <b-col sm="9" v-if="optionsLocal.coursemode=='IN-PERSON'" >
          <b-form-group label="Venue Title" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.courselocation"
              name="name"
              placeholder="Venue"
            />
          </b-form-group>
            <b-form-group label="Google Maps Url" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.courselocationUrl"
              name="name"
              placeholder="Google Maps Url"
            />
          </b-form-group>
            <b-form-group label="Venue Address" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.courselocationAddress"
              name="name"
              placeholder="Venue Address"
            />
          </b-form-group>
             <b-form-group label="Venue Room" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.courselocationRoom"
              name="name"
              placeholder="Venue Room"
            />
          </b-form-group>
        </b-col>
 <b-col sm="6" v-if="optionsLocal.coursemode=='IN-PERSON'" >
       
        </b-col>
       


        <!-- alert -->
        <b-col cols="12" class="mt-75">
          <b-form-group label="Course Categories" label-for="course-category">
            <category-selector
              :initialCategories="optionsLocal.CourseCategories"
              @selectionChanged="courseCategoryUpdated"
            />
          </b-form-group>
        </b-col>


        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            :disabled="isLoading ? true : false"
            variant="outline-secondary"
            @click.prevent="resetFile"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BBadge,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import CategorySelector from "../../course-category/components/CategorySelector.vue";
import AuthorSelector from "../../instructor/AuthorSelector.vue";
import CourseTypePicker from '../components/CourseTypePicker.vue';
 
 

export default {
  components: {
    BBadge,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    CategorySelector,
    AuthorSelector,
    CourseTypePicker
    
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      place: null, // optional,
      localOptions: {},
      isLoading: false,
      file: null,
      isUpdated: false,
      optionsLocal: JSON.parse(JSON.stringify(this.generalData.data)),
      profileFile: null,
      location: {
      lat: 41.0082376,
      lng: 28.97835889999999
    },
    options: { // is not required
      map: {/** other map options **/},
      marker: { /** marker options **/ },
      autocomplete: { /** autocomplete options **/ }
    },
    };
  },
  methods: {
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.optionsLocal.featuredImage = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },

    authorChanged(payload) {
      console.log(payload);
      this.optionsLocal.ClientInstructors = Array.isArray(payload)
        ? payload
        : [payload];

      console.log(this.optionsLocal);
    },

      courseModeUpdated(payload) {
      console.log("Catefy result in info ");
      console.log(payload);
      this.optionsLocal.coursemode = payload.name;
    },

    courseCategoryUpdated(payload) {
      console.log("Catefy result in info ");
      console.log(payload);
      this.optionsLocal.CourseCategories = Array.isArray(payload)
        ? payload
        : [payload];
    },

    sendInformation() {
      console.log(this.optionsLocal);
      console.log("update course  is launching with id : " + this.courseId);

     
      this.isLoading = true;
      axios({
        method: "put",
        url: `/lms/admin/course/updatecoursedetail/${this.courseId}`,
        data: {
          info: "general",
          file: null,
          content: this.optionsLocal,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
  },
  mounted() {
    
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
